<template>
  <div>
    <div v-if="!site.isSubscribed" class="columns is-marginless is-multiline">
      <div class="column is-12">
        <div class="box">
          <no-results
            v-if="['owner', 'agent', 'admin'].includes(userRole)"
            icon="heartbeat"
            :cta="{ label: 'Upgrade today' }"
            title="Upgrade to unlock website monitoring"
            message="Our 24/7 reactive monitoring is included as standard with all
            maintenance subscriptions. To unlock this popular feature, please upgrade your site today."
            style="margin: auto; max-width: 400px;"
            @click="upgrade"
          />
          <no-results
            v-else
            icon="heartbeat"
            title="Website monitoring unavailable"
            message="Our 24/7 reactive monitoring is not currently available for
          this site. Please contact the site owner to enable this feature."
            style="margin: auto; max-width: 400px;"
          />
        </div>
      </div>
    </div>
    <div v-else class="columns is-marginless is-multiline">
      <div class="column is-12 is-4-desktop is-3-widescreen">
        <div
          class="content has-text-grey has-padding-x-50 has-margin-bottom-50"
        >
          <h2>Monitoring</h2>

          <p>
            Our monitoring automatically checks your site to make sure it is up
            and running. We track error codes and response time, as well the
            size of the webpage. Use the filter below to change the display.
          </p>

          <section class="is-clearfix has-margin-bottom-100">
            <b-field label="Time frame">
              <b-select v-model="period" expanded @input="onPeriodChange">
                <option v-for="p in periods" :key="p.key" :value="p">
                  {{ p.label }}
                </option>
              </b-select>
            </b-field>
          </section>

          <b-message
            v-if="!$_.isEmpty(monitors.dateCached) && !loading"
            type="is-info"
          >
            <p>
              This report was cached
              {{ $moment(monitors.dateCached.toDate()).from() }}.
              <a @click="getUptimeReport({ fromCache: false })"
                >Click here to retrieve a fresh copy</a
              >.
            </p>
          </b-message>
        </div>
      </div>

      <div class="column is-12 is-8-desktop is-9-widescreen">
        <div class="columns">
          <div v-if="!loading && !monitor" class="column is-12">
            <b-message type="is-warning"
              >There is no monitoring data for this website yet.</b-message
            >
          </div>
        </div>

        <!-- Stats -->
        <monitoring-stats :monitor="monitor" :loading="loading || !monitor" />

        <div class="columns is-multiline">
          <div class="column is-12">
            <b-collapse class="card">
              <div slot="trigger" slot-scope="props" class="card-header">
                <p class="card-header-title">
                  <strong>Response times</strong>
                </p>

                <a class="card-header-icon has-text-grey-light">
                  <b-icon
                    :icon="props.open ? 'minus' : 'plus'"
                    size="is-small"
                  />
                </a>
              </div>

              <div class="card-content">
                <loading v-if="loading" />
                <div
                  v-else-if="!loading && !monitor"
                  class="column is-12 has-text-centered"
                >
                  <span class="has-text-grey-dark">
                    No
                    <strong>response time</strong> and
                    <strong>site size</strong> data recorded yet.
                  </span>
                </div>
                <div v-else>
                  <response-time-chart
                    v-if="monitor && activeTab === 'response-time'"
                    :monitor="monitor"
                    :period="period.key"
                  />
                </div>
              </div>
            </b-collapse>
          </div>

          <div class="column is-12">
            <b-collapse class="card">
              <div slot="trigger" slot-scope="props" class="card-header">
                <p class="card-header-title">
                  <strong>Logs</strong>
                </p>

                <a class="card-header-icon has-text-grey-light">
                  <b-icon
                    :icon="props.open ? 'minus' : 'plus'"
                    size="is-small"
                  />
                </a>
              </div>

              <div class="card-content">
                <loading v-if="loading" />
                <div
                  v-else-if="!loading && (!monitor || $_.isEmpty(monitor.logs))"
                  class="column is-12 has-text-centered"
                >
                  <span class="has-text-grey-dark">
                    No
                    <strong>log</strong> data recorded yet.
                  </span>
                </div>
                <div v-else class="columns">
                  <div class="column is-12 is-12-mobile">
                    <response-logs-table :monitor="monitor" />
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "response-time-chart": require("@shared/sites/monitoring/_responseTimeChart")
      .default,
    "monitoring-stats": () => import("./_monitoringStats"),
    "response-logs-table": () => import("./_responseLogsTable")
  },
  data() {
    return {
      loading: false,
      siteId: this.$route.params.siteId,
      monitors: {},
      report: {
        data: { raw: [], average: [] },
        dateCached: null,
        period: "day"
      },
      period: null,
      periods: [
        {
          key: "day",
          label: "Last 24 hours",
          response_times_average: "30",
          custom_uptime_ratios: "1"
        },
        {
          key: "week",
          label: "Last 7 days",
          response_times_average: "240",
          custom_uptime_ratios: "7"
        },
        {
          key: "month",
          label: "Last 30 days",
          response_times_average: "1440",
          custom_uptime_ratios: "30"
        }
      ],
      activeTab: "response-time"
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    userRole() {
      return this.$store.getters["sites/site/userRole"](this.siteId);
    },
    monitor() {
      return this.$_.first(this.monitors.monitors);
    },
    queryStartDate() {
      return this.$moment()
        .subtract(1, this.period.key)
        .unix();
    },
    queryEndDate() {
      return this.$moment()
        .endOf("day")
        .unix();
    },
    monitorQuery() {
      return this.$_.merge({}, this.period, {
        all_time_uptime_ratio: 1,
        all_time_uptime_durations: 1,
        response_times: 1,
        response_times_start_date: this.queryStartDate,
        response_times_end_date: this.queryEndDate,
        logs: 1
      });
    }
  },
  created() {
    if (this.site.isSubscribed) {
      const periodKey = this.$_.get(this.$route.query, "period", "day");
      this.period =
        this.$_.find(this.periods, period => {
          return period.key === periodKey;
        }) || this.periods[0];
      this.$nextTick(() => {
        this.getUptimeReport({ fromCache: true });
      });
    }
  },
  methods: {
    onPeriodChange() {
      this.$nextTick(() => {
        this.getUptimeReport({ fromCache: true });
      });
    },
    getUptimeReport({ fromCache }) {
      this.$router.push({ query: { period: this.period.key } });
      this.loading = true;

      this.$store
        .dispatch("sites/getUptimeReport", {
          period: this.period.key,
          siteId: this.siteId,
          fromCache: fromCache,
          query: this.monitorQuery
        })
        .then(report => {
          this.monitors = report;
        })
        .catch(error => {
          this.$toast.open({
            message: typeof error === "string" ? error : error.message,
            type: "is-danger"
          });
        })
        .finally(() => (this.loading = false));
    },
    upgrade() {
      this.$modal.open({
        component: () => import("@shared/contracts/_upgradeModal"),
        parent: this,
        width: 960,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          fqdn: this.site.fqdn,
          siteId: this.siteId,
          userId: this.site.authorId
        }
      });
    }
  }
};
</script>
